/* Top Line Framework Settings */
/*-->fonts */
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,400italic,300,300italic);
$settings: (
  vertSmall: 10px,
  vertMedium: 20px,
  vertLarge: 40px,
  gutterSmall: 10px,
  gutterMedium: 20px,
  gutterLarge: 40px,
  primaryColor: #3c8dbc,
  warningColor: #fcf8e3,
  dangerColor: darkred,
  successColor: #5cb85c,
);
/* Globals */
/*-->text colors */
$green: #5cb85c;
.green-words {
  color: $green;
  :hover {
    color: #2d2d2d;
  }
}
.red-words {
  color: map-get($settings, "dangerColor");
  :hover {
    color: #2d2d2d;
  }
}
div,
p,
span {
  font-family: "Fira Sans", sans-serif;
}

/*-->vertical padding */
.ts-vert-padding {
  &.ts-small {
    padding-top: map-get($settings, "vertSmall");
    padding-bottom: map-get($settings, "vertSmall");
  }
  &.ts-medium {
    padding-top: map-get($settings, "vertMedium");
    padding-bottom: map-get($settings, "vertMedium");
  }
  &.ts-large {
    padding-top: map-get($settings, "vertLarge");
    padding-bottom: map-get($settings, "vertLarge");
  }
}

/*-->vertical margin */
.ts-vert-margin {
  &.ts-small {
    margin-top: map-get($settings, "vertSmall");
    margin-bottom: map-get($settings, "vertSmall");
  }
  &.ts-medium {
    margin-top: map-get($settings, "vertMedium");
    margin-bottom: map-get($settings, "vertMedium");
  }
  &.ts-large {
    margin-top: map-get($settings, "vertLarge");
    margin-bottom: map-get($settings, "vertLarge");
  }
}

/*-->gutter */
.ts-waist-gutter {
  &.ts-small {
    padding-left: map-get($settings, "gutterSmall");
    padding-right: map-get($settings, "gutterSmall");
  }
  &.ts-medium {
    padding-left: map-get($settings, "gutterMedium");
    padding-right: map-get($settings, "gutterMedium");
  }
  &.ts-large {
    padding-left: map-get($settings, "gutterLarge");
    padding-right: map-get($settings, "gutterLarge");
  }
}

/*-->dashboard user profile dropdown */
.user-menu {
  .dropdown-toggle.img-circle {
    max-width: 50px;
    max-height: 50px;
    margin-top: 12px;
    span {
      margin-left: -0.8rem;
    }
  }
}
.user-header {
  #profile-image {
    height: 80px;
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid white;
  }
}

/*-->dashboard header profile image */
.main-header {
  > .navbar {
    max-height: 50px;
  }
}
.dropdown {
  &.user {
    margin-top: -13px;
    .dropdown-toggle {
      .img-circle {
        max-width: 45px;
        height: auto;
      }
    }
  }
}

/*-->dev dashboard sidebar menu profile info */
.main-sidebar {
  .user-panel {
    min-height: 7rem;
    .image {
      height: 50px;
      width: 50px;
    }
  }
}

/*-->animated item listing transitions */
.item-transition {
  transition: all 0.3s ease;
}
.item-move {
  /* applied to the element when moving */
  transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/*-->alerts */
.alert {
  margin-bottom: 0;
}

/*-->admin callout alerts */
.lead.callout:not(.callout-success) {
  background-color: darkred;
  color: white;
}

/*-->admin confirmation check */
.confirmation {
  color: white;
  &.warning {
    background: map-get($settings, "warningColor");
    > p,
    h4 {
      color: #8a6d3b !important;
    }
  }
}

/* Welcome Page */
#hero-header {
  margin-top: -2rem;
  min-height: 750px;
  background: transparent;
  font-family: "Fira Sans", sans-serif;

  > .hero-content {
    padding: 20px;
    float: right;
    margin-top: 6%;
    color: white;

    > h1.header {
      opacity: 0;
      margin-top: 50px;
      font-size: 10rem;
    }
    > p {
      opacity: 0;
      margin-top: 50px;
      font-size: 4rem;
      font-weight: lighter;
      font-style: italic;
    }
    > a.hero-btn {
      padding: 6px 20px;
      font-size: 3rem;
      border: 1px solid white;
      opacity: 0;
      color: white;
      text-decoration: none;
    }
  }
}

/* Home Page */
#app-navbar-collapse {
  .navbar-right {
    position: relative;
  }
}
.account-avatar {
  max-width: 45px;
  margin: 4px;
  clear: both;
  position: absolute;
  top: 0;
  left: -55px;

  > img {
    max-width: 100%;
  }
}

/* Admin User Manager */
#user-list {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 400px;

  .user-list-created-date {
    color: #a3a3a3;
    font-size: 1rem;
  }
}
#account-profile-image-preview {
  max-width: 400px;
  .img-circle {
    width: 250px;
    height: 250px;
  }
}

/* Admin account editing page */
/*-->configuration containers */
.config-container {
  padding: 20px;
}

/*-->profile photo preview */
#account-profile-image-preview {
  > img {
    max-width: 50%;
  }
}

/* Admin Host Controller */
/*-->host name edit boxes */
.hostname-edit-box {
  > input {
    margin-bottom: 10px;
  }
  display: none;
  padding: 10px;
}

/*-->host listing */
#host-manager-list input[type="text"] {
  width: 100%;
}
.host-name-change-action {
  &.red-text {
    color: red;
  }
  &.green-text {
    color: $green;
  }
  margin-left: 1rem;
}

/*-->host listing search */
#hostFilterLabel {
  margin-left: 10px;
}
#hostFilter {
  width: 90%;
  margin-left: 10px;
  padding: 5px;
}

/*-->host wizard */
#user-selector {
  .dropdown-menu {
    max-height: 40rem;
    overflow: hidden;
    overflow-y: scroll;
  }
}

/* Admin account listing page */
#searchUsers {
  width: 100%;
  margin-bottom: 5px;
}

/* User Account editor */
#rent-cafe-property-codes-container,
#funnel-community-ids-container,
#entrata-property-codes-container {
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

/*-->property listing on user's account */
#account-property-listing {
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px;
  background: white;
}

/* Lead Management View */
#lead-manager-wrap {
  .list-group {
    > a:hover {
      cursor: pointer;
    }
  }

  #next-export-execution {
    width: 100%;
    text-align: center;
  }
}

#active-user-accounts.list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;

  > .list-group-item.not-active {
    pointer-events: none;
    cursor: not-allowed !important;
    font-style: italic;
    background: #efefef;
  }
}

/* Account listings */
#account-properties-container {
  > .list-group {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }
}

/*-->activity boxes */
#activity-container {
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  background: #fff;
  padding: 5px;

  > #no-activity {
    > p {
      font-size: 2rem;
      margin-bottom: 0;
      color: #a3a3a3;
    }
  }

  > ul {
    list-style-type: none;
    padding-left: 0 !important;

    > li {
      margin-bottom: 5px;

      [class^="activity-"] {
        padding: 10px;
      }

      .activity-info {
        background: map-get($settings, "primaryColor");
      }

      .activity-default {
        background: #a3a3a3;
      }

      .activity-success {
        background: map-get($settings, "successColor");
      }

      .activity-warning {
        background-color: map-get($settings, "warningColor");
        border-color: #faebcc;
        > p {
          color: #8a6d3b !important;
        }
      }

      .activity-error {
        background: darkred;
      }
    }
  }

  p {
    margin-bottom: 0;
    color: white;
    font-size: 1.5rem;
  }
}

#property-ajax-loader {
  vertical-align: middle;

  &.activity-success {
    text-align: center;
    color: white;
    padding: 10px;
    background: #5cb85c;
    height: 64px;
    font-size: 1rem;

    > span {
      font-size: 1.5rem !important;
    }
  }
}

/** Admin Property Browse Page */
#property-browse-list {
  overflow: hidden;
  overflow-y: scroll;

  .floor-plan-item {
    background: #efefef;
    border: 1px solid black;
    padding: 2rem;
  }

  #floor-plan-unit-table {
    table {
      width: 100%;
      background: white;
      td {
        padding: 5px;
      }
    }
  }
}

/** Media Queries */
@media (max-width: 800px) {
  .hero-content {
    margin-top: 15% !important;
    > h1.header {
      font-size: 4rem !important;
    }
    > p {
      font-size: 2rem !important;
    }
  }
  /*-->profile photo preview */
  #account-profile-image-preview {
    > img {
      max-width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .account-avatar {
    display: none;
  }
  .user-menu {
    .dropdown-toggle.img-circle {
      margin-top: 22px;
    }
  }
}

.engrain-checklist,
.scheduler-checklist,
.apartment-list-checklist {
  input[type="radio"],
  input[type="checkbox"] {
    cursor: pointer;
    outline-style: none;
    position: relative;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    margin-bottom: -0.25em;
    margin-right: 5px;
    vertical-align: top;
  }

  input[type="checkbox"] {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    color: #484848;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  input[type="radio"] {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 50px;
    color: #484848;
    outline-style: none;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
  }

  input[type="checkbox"]:checked:after,
  input[type="radio"]:checked:after {
    background: #4a8cff;
    border: solid 1px #4a8cff;
    color: #ffffff;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  input[type="checkbox"]:checked:after {
    border-radius: 4px;
    content: "";
    font-size: 0.7em;
    line-height: 1.1;
  }

  input[type="radio"]:checked:after {
    border-radius: 50px;
    content: "";
    font-size: 0.34em;
    line-height: 2.3;
  }

  input[type="radio"]:disabled,
  input[type="checkbox"]:disabled {
    background: #f2f6f9;
    border: solid 1px #e8e8e8;
    pointer-events: none;
  }

  input[type="radio"]:disabled:checked:after,
  input[type="checkbox"]:disabled:checked:after {
    color: #cdd3d9;
    background: #f2f6f9;
    border: solid 1px #e8e8e8;
  }

  .apartment-checkbox-parent {
    padding: 10px;
    line-height: 28px;
    // border-top: solid 1px #d6d6d6;
  }
  .apartment-checkbox-parent:nth-child(odd) {
    background-color: hsla(195, 24%, 93%, 0.5);
    hr {
      border-top: 1px dashed hsla(270, 4%, 71%, 0.5);
    }
  }
  .apartment-checkbox-parent:nth-child(even) {
    background-color: hsla(270, 4%, 71%, 0.5);
    hr {
      border-top: 1px dashed hsla(195, 24%, 93%, 0.5);
    }
  }

  .Checkbox-child {
    margin-bottom: 8px;
  }

  ul {
    list-style: none;
  }

  .Checkbox-child li {
    margin-bottom: 0px;
  }

  /* Accordion */

  // .Accordion:after {
  //   content: "\002B";
  //   color: #4f5766;
  //   font-weight: bold;
  //   float: right;
  //   margin-left: 5px;
  // }

  // .Accordion--active:after {
  //   content: "\2212";
  //   color: #4f5766;
  // }

  .Accordion-panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  hr {
    margin: 0px;
  }

  // .Accordion-panel:last-of-type {
  //   border-bottom: solid 1px #3a9dc4;
  // }
}
