/* Top Line Framework Settings */
/*-->fonts */
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,400italic,300,300italic);
/* Globals */
/*-->text colors */
.green-words {
  color: #5cb85c;
}

.green-words :hover {
  color: #2d2d2d;
}

.red-words {
  color: darkred;
}

.red-words :hover {
  color: #2d2d2d;
}

div,
p,
span {
  font-family: "Fira Sans", sans-serif;
}

/*-->vertical padding */
.ts-vert-padding.ts-small {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ts-vert-padding.ts-medium {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ts-vert-padding.ts-large {
  padding-top: 40px;
  padding-bottom: 40px;
}

/*-->vertical margin */
.ts-vert-margin.ts-small {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ts-vert-margin.ts-medium {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ts-vert-margin.ts-large {
  margin-top: 40px;
  margin-bottom: 40px;
}

/*-->gutter */
.ts-waist-gutter.ts-small {
  padding-left: 10px;
  padding-right: 10px;
}

.ts-waist-gutter.ts-medium {
  padding-left: 20px;
  padding-right: 20px;
}

.ts-waist-gutter.ts-large {
  padding-left: 40px;
  padding-right: 40px;
}

/*-->dashboard user profile dropdown */
.user-menu .dropdown-toggle.img-circle {
  max-width: 50px;
  max-height: 50px;
  margin-top: 12px;
}

.user-menu .dropdown-toggle.img-circle span {
  margin-left: -0.8rem;
}

.user-header #profile-image {
  height: 80px;
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid white;
}

/*-->dashboard header profile image */
.main-header > .navbar {
  max-height: 50px;
}

.dropdown.user {
  margin-top: -13px;
}

.dropdown.user .dropdown-toggle .img-circle {
  max-width: 45px;
  height: auto;
}

/*-->dev dashboard sidebar menu profile info */
.main-sidebar .user-panel {
  min-height: 7rem;
}

.main-sidebar .user-panel .image {
  height: 50px;
  width: 50px;
}

/*-->animated item listing transitions */
.item-transition {
  transition: all 0.3s ease;
}

.item-move {
  /* applied to the element when moving */
  transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/*-->alerts */
.alert {
  margin-bottom: 0;
}

/*-->admin callout alerts */
.lead.callout:not(.callout-success) {
  background-color: darkred;
  color: white;
}

/*-->admin confirmation check */
.confirmation {
  color: white;
}

.confirmation.warning {
  background: #fcf8e3;
}

.confirmation.warning > p,
.confirmation.warning h4 {
  color: #8a6d3b !important;
}

/* Welcome Page */
#hero-header {
  margin-top: -2rem;
  min-height: 750px;
  background: transparent;
  font-family: "Fira Sans", sans-serif;
}

#hero-header > .hero-content {
  padding: 20px;
  float: right;
  margin-top: 6%;
  color: white;
}

#hero-header > .hero-content > h1.header {
  opacity: 0;
  margin-top: 50px;
  font-size: 10rem;
}

#hero-header > .hero-content > p {
  opacity: 0;
  margin-top: 50px;
  font-size: 4rem;
  font-weight: lighter;
  font-style: italic;
}

#hero-header > .hero-content > a.hero-btn {
  padding: 6px 20px;
  font-size: 3rem;
  border: 1px solid white;
  opacity: 0;
  color: white;
  text-decoration: none;
}

/* Home Page */
#app-navbar-collapse .navbar-right {
  position: relative;
}

.account-avatar {
  max-width: 45px;
  margin: 4px;
  clear: both;
  position: absolute;
  top: 0;
  left: -55px;
}

.account-avatar > img {
  max-width: 100%;
}

/* Admin User Manager */
#user-list {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 400px;
}

#user-list .user-list-created-date {
  color: #a3a3a3;
  font-size: 1rem;
}

#account-profile-image-preview {
  max-width: 400px;
}

#account-profile-image-preview .img-circle {
  width: 250px;
  height: 250px;
}

/* Admin account editing page */
/*-->configuration containers */
.config-container {
  padding: 20px;
}

/*-->profile photo preview */
#account-profile-image-preview > img {
  max-width: 50%;
}

/* Admin Host Controller */
/*-->host name edit boxes */
.hostname-edit-box {
  display: none;
  padding: 10px;
}

.hostname-edit-box > input {
  margin-bottom: 10px;
}

/*-->host listing */
#host-manager-list input[type="text"] {
  width: 100%;
}

.host-name-change-action {
  margin-left: 1rem;
}

.host-name-change-action.red-text {
  color: red;
}

.host-name-change-action.green-text {
  color: #5cb85c;
}

/*-->host listing search */
#hostFilterLabel {
  margin-left: 10px;
}

#hostFilter {
  width: 90%;
  margin-left: 10px;
  padding: 5px;
}

/*-->host wizard */
#user-selector .dropdown-menu {
  max-height: 40rem;
  overflow: hidden;
  overflow-y: scroll;
}

/* Admin account listing page */
#searchUsers {
  width: 100%;
  margin-bottom: 5px;
}

/* User Account editor */
#rent-cafe-property-codes-container,
#funnel-community-ids-container,
#entrata-property-codes-container {
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

/*-->property listing on user's account */
#account-property-listing {
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px;
  background: white;
}

/* Lead Management View */
#lead-manager-wrap .list-group > a:hover {
  cursor: pointer;
}

#lead-manager-wrap #next-export-execution {
  width: 100%;
  text-align: center;
}

#active-user-accounts.list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

#active-user-accounts.list-group > .list-group-item.not-active {
  pointer-events: none;
  cursor: not-allowed !important;
  font-style: italic;
  background: #efefef;
}

/* Account listings */
#account-properties-container > .list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

/*-->activity boxes */
#activity-container {
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  background: #fff;
  padding: 5px;
}

#activity-container > #no-activity > p {
  font-size: 2rem;
  margin-bottom: 0;
  color: #a3a3a3;
}

#activity-container > ul {
  list-style-type: none;
  padding-left: 0 !important;
}

#activity-container > ul > li {
  margin-bottom: 5px;
}

#activity-container > ul > li [class^="activity-"] {
  padding: 10px;
}

#activity-container > ul > li .activity-info {
  background: #3c8dbc;
}

#activity-container > ul > li .activity-default {
  background: #a3a3a3;
}

#activity-container > ul > li .activity-success {
  background: #5cb85c;
}

#activity-container > ul > li .activity-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
}

#activity-container > ul > li .activity-warning > p {
  color: #8a6d3b !important;
}

#activity-container > ul > li .activity-error {
  background: darkred;
}

#activity-container p {
  margin-bottom: 0;
  color: white;
  font-size: 1.5rem;
}

#property-ajax-loader {
  vertical-align: middle;
}

#property-ajax-loader.activity-success {
  text-align: center;
  color: white;
  padding: 10px;
  background: #5cb85c;
  height: 64px;
  font-size: 1rem;
}

#property-ajax-loader.activity-success > span {
  font-size: 1.5rem !important;
}

/** Admin Property Browse Page */
#property-browse-list {
  overflow: hidden;
  overflow-y: scroll;
}

#property-browse-list .floor-plan-item {
  background: #efefef;
  border: 1px solid black;
  padding: 2rem;
}

#property-browse-list #floor-plan-unit-table table {
  width: 100%;
  background: white;
}

#property-browse-list #floor-plan-unit-table table td {
  padding: 5px;
}

/** Media Queries */
@media (max-width: 800px) {
  .hero-content {
    margin-top: 15% !important;
  }
  .hero-content > h1.header {
    font-size: 4rem !important;
  }
  .hero-content > p {
    font-size: 2rem !important;
  }
  /*-->profile photo preview */
  #account-profile-image-preview > img {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .account-avatar {
    display: none;
  }
  .user-menu .dropdown-toggle.img-circle {
    margin-top: 22px;
  }
}

.engrain-checklist,
.scheduler-checklist,
.apartment-list-checklist {
  /* Accordion */
}

.engrain-checklist input[type="radio"],
.engrain-checklist input[type="checkbox"],
.scheduler-checklist input[type="radio"],
.scheduler-checklist input[type="checkbox"],
.apartment-list-checklist input[type="radio"],
.apartment-list-checklist input[type="checkbox"] {
  cursor: pointer;
  outline-style: none;
  position: relative;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  margin-bottom: -0.25em;
  margin-right: 5px;
  vertical-align: top;
}

.engrain-checklist input[type="checkbox"],
.scheduler-checklist input[type="checkbox"],
.apartment-list-checklist input[type="checkbox"] {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  color: #484848;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.engrain-checklist input[type="radio"],
.scheduler-checklist input[type="radio"],
.apartment-list-checklist input[type="radio"] {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 50px;
  color: #484848;
  outline-style: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.engrain-checklist input[type="checkbox"]:checked:after,
.engrain-checklist input[type="radio"]:checked:after,
.scheduler-checklist input[type="checkbox"]:checked:after,
.scheduler-checklist input[type="radio"]:checked:after,
.apartment-list-checklist input[type="checkbox"]:checked:after,
.apartment-list-checklist input[type="radio"]:checked:after {
  background: #4a8cff;
  border: solid 1px #4a8cff;
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
}

.engrain-checklist input[type="checkbox"]:checked:after,
.scheduler-checklist input[type="checkbox"]:checked:after,
.apartment-list-checklist input[type="checkbox"]:checked:after {
  border-radius: 4px;
  content: "";
  font-size: 0.7em;
  line-height: 1.1;
}

.engrain-checklist input[type="radio"]:checked:after,
.scheduler-checklist input[type="radio"]:checked:after,
.apartment-list-checklist input[type="radio"]:checked:after {
  border-radius: 50px;
  content: "";
  font-size: 0.34em;
  line-height: 2.3;
}

.engrain-checklist input[type="radio"]:disabled,
.engrain-checklist input[type="checkbox"]:disabled,
.scheduler-checklist input[type="radio"]:disabled,
.scheduler-checklist input[type="checkbox"]:disabled,
.apartment-list-checklist input[type="radio"]:disabled,
.apartment-list-checklist input[type="checkbox"]:disabled {
  background: #f2f6f9;
  border: solid 1px #e8e8e8;
  pointer-events: none;
}

.engrain-checklist input[type="radio"]:disabled:checked:after,
.engrain-checklist input[type="checkbox"]:disabled:checked:after,
.scheduler-checklist input[type="radio"]:disabled:checked:after,
.scheduler-checklist input[type="checkbox"]:disabled:checked:after,
.apartment-list-checklist input[type="radio"]:disabled:checked:after,
.apartment-list-checklist input[type="checkbox"]:disabled:checked:after {
  color: #cdd3d9;
  background: #f2f6f9;
  border: solid 1px #e8e8e8;
}

.engrain-checklist .apartment-checkbox-parent,
.scheduler-checklist .apartment-checkbox-parent,
.apartment-list-checklist .apartment-checkbox-parent {
  padding: 10px;
  line-height: 28px;
}

.engrain-checklist .apartment-checkbox-parent:nth-child(odd),
.scheduler-checklist .apartment-checkbox-parent:nth-child(odd),
.apartment-list-checklist .apartment-checkbox-parent:nth-child(odd) {
  background-color: rgba(233, 239, 241, 0.5);
}

.engrain-checklist .apartment-checkbox-parent:nth-child(odd) hr,
.scheduler-checklist .apartment-checkbox-parent:nth-child(odd) hr,
.apartment-list-checklist .apartment-checkbox-parent:nth-child(odd) hr {
  border-top: 1px dashed rgba(181, 178, 184, 0.5);
}

.engrain-checklist .apartment-checkbox-parent:nth-child(even),
.scheduler-checklist .apartment-checkbox-parent:nth-child(even),
.apartment-list-checklist .apartment-checkbox-parent:nth-child(even) {
  background-color: rgba(181, 178, 184, 0.5);
}

.engrain-checklist .apartment-checkbox-parent:nth-child(even) hr,
.scheduler-checklist .apartment-checkbox-parent:nth-child(even) hr,
.apartment-list-checklist .apartment-checkbox-parent:nth-child(even) hr {
  border-top: 1px dashed rgba(233, 239, 241, 0.5);
}

.engrain-checklist .Checkbox-child,
.scheduler-checklist .Checkbox-child,
.apartment-list-checklist .Checkbox-child {
  margin-bottom: 8px;
}

.engrain-checklist ul,
.scheduler-checklist ul,
.apartment-list-checklist ul {
  list-style: none;
}

.engrain-checklist .Checkbox-child li,
.scheduler-checklist .Checkbox-child li,
.apartment-list-checklist .Checkbox-child li {
  margin-bottom: 0px;
}

.engrain-checklist .Accordion-panel,
.scheduler-checklist .Accordion-panel,
.apartment-list-checklist .Accordion-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.engrain-checklist hr,
.scheduler-checklist hr,
.apartment-list-checklist hr {
  margin: 0px;
}
